import { React, useState, useEffect } from "react";
import { Select, MenuItem, FormHelperText, FormControl, InputLabel } from '@material-ui/core'
import '../../App.css'
import {
  Grid,
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { MultiSelect } from "react-multi-select-component";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ImgUpload from "./imgUpload";
import FileUpload from "./fileUpload";
import Nav from "../dashboard/nav";

export default function UpdateApplication() {
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setEmail] = useState("");
  const [MobileNo, setMobileNo] = useState("");
  const [educationQualification, seteducationQualification] = useState("");
  const [yearOfPassout, setyearOfPassout] = useState("");
  const [DateOfBirth, setDateOfBirth] = useState("");
  const [DateOfJoin, setDateOfJoin] = useState("");
  const [city, setcity] = useState("");
  const [zipcode, setzipcode] = useState("");
  const [Percentage, setPercentage] = useState("");
  const [totalExperience, settotalExperience] = useState("");
  const [relevantExperience, setrelevantExperience] = useState("");
  const [stream, setStream] = useState("");
  const [interviewStatus, setinterviewStatus] = useState("");
  const [interviewer, setInterviewer] = useState("");
  const [shortlisted, setShortlisted] = useState("");
  const [address, setaddress] = useState("");
  const [reference, setReference] = useState("");
  const [remarks, setRemarks] = useState("");
  const [skills, setSelected] = useState([]);
  const [level, setLevel] = useState("");
  const [profileImage, setprofileImage] = useState("")
  const [profileResume, setprofileResume] = useState("")
  const [Ctc, setCtc] = useState("")
  const [Etc, setEtc] = useState("")
  const [CurrentProject, setCurrentProject] = useState("")
  const [LocationPrefered, setLocationPrefered] = useState("")
  const [CurrentLocation, setCurrentLocation] = useState("")
  const [Communication, setCommunication] = useState("")
  const [FirstRound, setFirstRound] = useState("")
  const [SecondRound, setSecondRound] = useState("")
  const [branch, setbranch] = useState("")
  const [ExistingEmployee, setExistingEmployee] = useState("")
  const [DateOfav, setDateOfav] = useState("")
  const [country,setCountry]=useState("")

  const [interviewSchedule,setInterviewSchedule]=useState("")

  
  let options =  [
    { label: 'react.js ', value: 'react.js' },
    { label: 'NODE.js ', value: 'NODE.js' },
    { label: 'MongoDB ', value: 'MongoDB' },
    { label: 'javaScript ', value: 'javaScript' },
    { label: 'HTML ', value: 'HTML' },
    { label: 'CSS ', value: 'CSS' },
    { label: 'SQL ', value: 'SQL' },
    { label: 'Java ', value: 'Java' },
    { label: 'spring ', value: 'spring' },
    { label: 'SalesForce ', value: 'SalesForce' },
    { label: 'Python ', value: 'Python' },
    { label: 'REST API ', value: 'REST API' },
    { label: 'GRAPHQL ', value: 'GRAPHQL' },
    { label: 'AWS', value: 'AWS' },
    { label: 'Docker', value: 'Docker' },
    { label: 'Jenkins', value: 'Jenkins' },
    { label: 'Maven', value: 'Maven' },
    { label: 'Terraform ', value: 'Terraform' },
    { label: 'Oracle SQL', value: 'OracleSQl' },
    { label: 'Snowflake', value: 'Snowflake' },
    { label: 'Tableau', value: 'Tableau' },
    { label: 'PowerBI', value: 'PowerBI' },
    { label: 'Apex', value: 'Apex' },
    { label: 'Visual Force', value: 'VisualForce' },
    { label: 'Lightning aura components (LAC)', value: 'LightningAuraComponents' },
    { label: 'lightning web components (LWC)', value: 'LightningWebComponents' },
    { label: 'Oracle Applications Technical', value: 'OracleTechnical' },
    { label: 'Oracle Applications Functional', value: 'OracleFunctional' },
    { label: 'QA-Application Testing', value: 'QAApplicationTesting' },
    { label: 'SAP BODS', value: 'SAPBODS' },
    { label: 'SAP FICO', value: 'SAPFICO' },
    {label:"SAP MII",value:"SAPMII"},
    {label:"SAPSD",value:"SAPSD"},
    { label: 'SAP MM', value: 'SAPMM' },
    { label: 'MLOPS', value: 'MLOPS' },
    { label: 'E Commerce ', value: 'ECommerce' },
    { label: 'SAP ', value: 'SAP' },
    { label: 'Dell bhoomi ', value: 'DellBhoomi' },
    { label: 'content writer ', value: 'ContentWriter' },
    { label: 'Magento ', value: 'Magento' },
    { label: 'HTML ', value: 'HTML' },
    { label: 'CSS ', value: 'CSS' },
    { label: 'SQL ', value: 'SQL' },
    { label: 'Java ', value: 'Java' },
    { label: 'spring ', value: 'spring' },
    { label: 'MANUALTESTING ', value: 'MANUALTESTING' },
    { label: 'AUTOMATIONTESTING ', value: 'Automation testing' },
  ];
  let FresherOptions =  [
    { label: 'react.js ', value: 'react.js' },
    { label: 'NODE.js ', value: 'NODE.js' },
    { label: 'MongoDB ', value: 'MongoDB' },
    { label: 'javaScript ', value: 'javaScript' },
    { label: 'HTML ', value: 'HTML' },
    { label: 'CSS ', value: 'CSS' },
    { label: 'SQL ', value: 'SQL' },
    { label: 'Java ', value: 'Java' },
    { label: 'spring ', value: 'spring' },
    { label: 'SalesForce ', value: 'SalesForce' },
    { label: 'Python ', value: 'Python' },
    { label: 'REST API ', value: 'REST API' },
    { label: 'GRAPHQL ', value: 'GRAPHQL' },
    { label: 'AWS', value: 'AWS' },
    { label: 'Docker', value: 'Docker' },
    { label: 'Jenkins', value: 'Jenkins' },
    { label: 'Maven', value: 'Maven' },
    { label: 'Terraform ', value: 'Terraform' },
    { label: 'Oracle SQL', value: 'OracleSQl' },
    { label: 'Data Science', value: 'DataScience' },
    { label: 'Snowflake', value: 'Snowflake' },
    { label: 'Tableau', value: 'Tableau' },
    { label: 'PowerBI', value: 'PowerBI' },
    { label: 'Apex', value: 'Apex' },
    { label: 'Visual Force', value: 'VisualForce' },
    { label: 'Lightning aura components (LAC)', value: 'LightningAuraComponents' },
    { label: 'lightning web components (LWC)', value: 'LightningWebComponents' },
    { label: 'Oracle Applications Technical', value: 'OracleTechnical' },
    { label: 'Oracle Applications Functional', value: 'OracleFunctional' },
    { label: 'QA-Application Testing', value: 'QAApplicationTesting' },
    { label: 'SAP BODS', value: 'SAPBODS' },
    { label: 'SAP FICO', value: 'SAPFICO' },
    {label:"SAP MII",value:"SAPMII"},
    {label:"SAPSD",value:"SAPSD"},
    { label: 'SAP MM', value: 'SAPMM' },
    { label: 'MLOPS', value: 'MLOPS' },
    { label: 'E Commerce ', value: 'ECommerce' },
    { label: 'SAP ', value: 'SAP' },
    { label: 'Dell bhoomi ', value: 'DellBhoomi' },
    { label: 'content writer ', value: 'ContentWriter' },
    { label: 'Magento ', value: 'Magento' },
    { label: 'MANUALTESTING ', value: 'MANUALTESTING' },
    { label: 'AUTOMATIONTESTING ', value: 'Automation testing' },
  ];
  const UIoptions = [
    { label: "react.js ", value: "react.js" },
    { label: "NODE.js ", value: "NODE.js" },
    { label: "MongoDB ", value: "MongoDB" },
    { label: "javaScript ", value: "javaScript" },
    { label: "HTML ", value: "HTML" },
    { label: "CSS ", value: "CSS" },
    { label: "SQL ", value: "SQL" },
    { label: "Java ", value: "Java" },
    { label: "spring ", value: "spring" },
    { label: "SalesForce ", value: "SalesForce" },
    { label: "Python ", value: "Python" },
    { label: "REST API ", value: "REST API" },
    { label: "GRAPHQL ", value: "GRAPHQL" },
  ];

  const AWSoptions = [
    { label: "AWS", value: "AWS" },
    { label: "Docker", value: "Docker" },
    { label: "Jenkins", value: "Jenkins" },
    { label: "Maven", value: "Maven" },
    { label: "Terraform ", value: "Terraform" },

  ];
  const DataOptions = [
    { label: "Oracle SQL", value: "OracleSQl" },
    { label: "Snowflake", value: "Snowflake" },
    { label: "Tableau", value: "Tableau" },
    { label: "PowerBI", value: "PowerBI" },
  ]
  const SFOptions = [
    { label: "Apex", value: "Apex" },
    { label: "Visual Force", value: "VisualForce" },
    { label: "Lightning aura components (LAC)", value: "LightningAuraComponents" },
    { label: "lightning web components (LWC)", value: "LightningWebComponents" },
  ]
  const ERPOptions = [
    { label: "Oracle Applications Technical", value: "OracleTechnical", options: SFOptions },
    { label: "Oracle Applications Functional", value: "OracleFunctional" },
    { label: "QA-Application Testing", value: "QAApplicationTesting" },
    { label: "SAP BODS", value: "SAPBODS" },
    { label: "SAP FICO", value: "SAPFICO" },
    {label:"SAP MII",value:"SAPMII"},
    {label:"SAPSD",value:"SAPSD"},
    { label: "SAP MM", value: "SAPMM" },
    { label: "MLOPS", value: "MLOPS" },

  ] 
  const HybrisOption = [
    { label: "E Commerce ", value: "ECommerce" },
    { label: "SAP ", value: "SAP" },

  ]
  const otherOptions = [
    { label: "Dell bhoomi ", value: "DellBhoomi" },
    { label: "content writer ", value: "ContentWriter" },
    { label: "Magento ", value: "Magento" },

  ]

  const JavaOptions =[
    { label: "HTML ", value: "HTML" },
    { label: "CSS ", value: "CSS" },
    { label: "SQL ", value: "SQL" },
    { label: "Java ", value: "Java" },
    { label: "spring ", value: "spring" },
  ]

  const testingOptions=[
    { label: "MANUALTESTING ", value: "MANUALTESTING" },
    { label: 'AUTOMATIONTESTING ', value: 'Automation testing' },

  ]
  



  if (stream === "FullStack") {
    options = UIoptions
  }
  if (stream === 'FrontEnd') {
    options = UIoptions;
  }
  if (stream === 'Dotnet') {
    options = JavaOptions;
  }
  if (stream === "NodeJS") {
    options = UIoptions
  }
  if (stream === "DevOps") {
    options = AWSoptions
  }
  if (stream === "Data") {
    options = DataOptions
  }
  if (stream === "SalesForce") {
    options = SFOptions
  }
  if (stream === "ERP") {
    options = ERPOptions
  }
  if (stream === "Hybris") {
    options = HybrisOption
  }
  if(stream ==="Java"){
    options=JavaOptions
  }
  if(stream ==="CSOD"){
    options=[]
  }
  if(stream ==="Testing"){
    options=testingOptions
  }
  if (stream === 'Fresher') {
    options = FresherOptions;
  }
  if(stream ==="others"){
    options=otherOptions
  }

  const params = useParams();

  const handleImageUpload = async () => {
    const imgUrl = await fetch("https://careersbe.vithiit.com/uploadImage", {
      method: 'POST',
      body: profileImage,
      redirect: 'follow'
    }).then(response => response.text())
      .then(result => setprofileImage(result))
      .catch(error => console.log('error', error))
    alert("image uploaded successfully")
  }

  const handleResumeUpload = async () => {
    const resUrl = await fetch("https://careersbe.vithiit.com/uploadFile", {
      method: 'POST',
      body: profileResume,
      redirect: 'follow'
    }).then(response => response.text())
      .then(result => setprofileResume(result))
      .catch(error => console.log('error', error))
    alert("image uploaded successfully")
  }
  const handleSelectValue = (e) => {
    setSelected(Array.isArray(e) ? e.map(x => x.value) : []);
  }
  const navigate = useNavigate()

  useEffect(() => {
    getApplicantDetails();
  }, [])

  const getApplicantDetails = async () => {
    let result = await fetch(`https://careersbe.vithiit.com/getdetails/${params.id}`, {
      headers: {
        authorization: JSON.parse(localStorage.getItem('token'))
      }
    });
    result = await result.json();
    setfirstName(result.firstName)
    setlastName(result.lastName)
    setEmail(result.email)
    setMobileNo(result.MobileNo)
    seteducationQualification(result.educationQualification)
    setyearOfPassout(result.yearOfPassout)
    setDateOfBirth(result.DateOfBirth)
    setDateOfJoin(result.DateOfJoin)
    setcity(result.city)
    setzipcode(result.zipcode)
    setPercentage(result.Percentage)
    settotalExperience(result.totalExperience)
    setrelevantExperience(result.relevantExperience)
    setStream(result.stream)
    setinterviewStatus(result.interviewStatus)
    setInterviewer(result.interviewer)
    setShortlisted(result.shortlisted)
    setaddress(result.address)
    setReference(result.reference)
    setRemarks(result.remarks)
    setSelected(result.skills)
    setLevel(result.level)
    setprofileImage(result.profileImage)
    setprofileResume(result.profileResume)
    setCtc(result.Ctc)
    setEtc(result.Etc)
    setCurrentProject(result.CurrentProject)
    setCurrentLocation(result.CurrentLocation)
    setLocationPrefered(result.LocationPrefered)
    setCommunication(result.Communication)
    setFirstRound(result.FirstRound)
    setSecondRound(result.SecondRound)
    setbranch(result.branch)
    setExistingEmployee(result.ExistingEmployee)
    setDateOfav(result.DateOfav)
    setCountry(result.country)
setInterviewSchedule(result.interviewSchedule)
  }
  const updateApplicant = async () => {

    let result = await fetch(`https://careersbe.vithiit.com/getdetails/${params.id}`, {
      method: 'Put',
      body: JSON.stringify({
        firstName,
        lastName,
        email,
        MobileNo,
        educationQualification,
        yearOfPassout,
        DateOfBirth,
        city,
        zipcode,
        skills,
        level,
        Percentage,
        totalExperience,
        relevantExperience,
        stream,
        DateOfJoin,
        interviewStatus,
        interviewer,
        shortlisted,
        address,
        reference,
        remarks,
        profileImage,
        profileResume,
        Ctc,
        Etc,
        CurrentProject,
        CurrentLocation,
        LocationPrefered,
        Communication,
        FirstRound,
        SecondRound,
        branch,
        ExistingEmployee,
        DateOfav,
        country,
        interviewSchedule
      }),
      headers: {
        "Content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem('token'))
      }
    });

    result = await result.json();
    console.log(result)
    alert("update success");
    navigate("/dashboard/app")
  };


  return (
    <div>
      <Nav />
      <div className="App" style={{ display: "flex", maxWidth: "100vw", padding: "px 0px", marginRight: "-800px" }}>
        <Grid>
          <Card style={{ maxWidth: "100vw", padding: "0px 0px", marginLeft: "270px" }}>
            <CardContent>
              <Typography gutterBottom variant="h3" align="center" marginBottom={5}>
                Update Form
              </Typography>
              <Typography gutterBottom variant="h5" align="left" paddingLeft={1} marginLeft={-1} style={{ background: "#787878", marginBottom: 15, fontFamily: "sans-serif", color: "white", paddingRight: 10 }}>
                Personal details
              </Typography>
              <form>
                <Grid container spacing={1}>
                  <div className="educ" style={{ display: "flex", gap: 4 }} >
                    <Grid xs={12} sm={4} >
                      <TextField
                        placeholder="Enter first name"
                        label="First Name"
                        variant="outlined"
                        value={firstName}

                        onChange={(e) => setfirstName(e.target.value)}
                        fullWidth
                        required
                        sx={{ fontWeight: 900, color: "red" }}
                      />
                    </Grid>
                    <Grid xs={12} sm={4} >
                      <TextField
                        placeholder="Enter last name"
                        label="Last Name"
                        variant="outlined"
                        value={lastName}
                        onChange={(e) => setlastName(e.target.value)}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid xs={12} sm={4} >
                      <TextField
                        type="email"
                        placeholder="Enter email"
                        label="Email"
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid xs={12} sm={4} >
                      <TextField
                        placeholder="Enter mobile number"
                        label="Mobile"
                        variant="outlined"
                        value={MobileNo}
                        onChange={(e) => setMobileNo(e.target.value)}
                        fullWidth
                        required
                      />
                    </Grid>

                      <Grid xs={12} sm={4}>
                        <FormControl variant="filled" fullWidth>
                          <InputLabel id="edu-stack">Location</InputLabel>
                          <Select
                            labelId="edu-stack"
                            id="demo-simple-select"
                            label="Level"
                            variant="outlined"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            fullWidth
                            required
                          >
                            <MenuItem value="India">India</MenuItem>
                            <MenuItem value="US">US</MenuItem>
                            <MenuItem value="Australia">Australia</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                  </div>
                  {/* -----education detailes---- */}

                  <Grid xs={12} sm={0} >
                    <Typography gutterBottom variant="h5" align="left" paddingLeft={2} marginLeft={0} marginTop={1} style={{ background: "#787878", fontFamily: "sans-serif", color: "white" }}>
                      Education details
                    </Typography>

                    {/* value={stream}
                    onChange={(e) => setStream(e.target.value)} */}
                    <div className="educ" style={{ display: "flex", gap: 4 }} >
                      <Grid xs={12} sm={4} >
                        <FormControl variant="filled" fullWidth>
                          <InputLabel id="edu-stack">Education Qualification</InputLabel>
                          <Select
                            labelId="edu-stack"
                            id="demo-simple-select"
                            label="Level"
                            variant="outlined"
                            value={educationQualification}
                            onChange={(e) => seteducationQualification(e.target.value)}
                            fullWidth
                            required
                          >
                            <MenuItem value="B.Tech">B.Tech</MenuItem>
                            <MenuItem value="M.Tech">M.Tech</MenuItem>
                            <MenuItem value="BSC">B.S.C</MenuItem>
                            <MenuItem value="MBA">M.B.A</MenuItem>
                            <MenuItem value="others">others</MenuItem>

                          </Select>

                        </FormControl>
                      </Grid>
                      <Grid xs={12} sm={4} item spacing={1}>
                        <TextField
                          placeholder="Enter year of passout"
                          label="year of passout"
                          variant="outlined"
                          value={yearOfPassout}
                          onChange={(e) => setyearOfPassout(e.target.value)}
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid xs={12} sm={4} item spacing={1}>
                        <TextField
                          placeholder="enter Your branch"
                          label="Branch"
                          variant="outlined"
                          value={branch}
                          onChange={(e) => setbranch(e.target.value)}
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid xs={12} sm={4} item spacing={1}>
                        <TextField
                          placeholder="Enter percentage"
                          label="percentage"
                          variant="outlined"
                          value={Percentage}
                          onChange={(e) => setPercentage(e.target.value)}
                          fullWidth

                        />
                      </Grid>

                    </div>

                  </Grid>
                  {/* -----education detailes---- */}

                  <Grid xs={12} sm={0} >
                    <Typography gutterBottom variant="h5" align="left" paddingLeft={2} marginLeft={0} marginTop={1} style={{ background: "#787878", fontFamily: "sans-serif", color: "white" }}>
                      Skill sets
                    </Typography>

                    <div className="skills" style={{ display: "flex", gap: 5 }} >
                      <Grid item xs={12} sm={6}>
                        {/* value={stream}
                    onChange={(e) => setStream(e.target.value)} */}
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="tech-stack">tech stack</InputLabel>
                          <Select
                            labelId="tech-stack"
                            id="demo-simple-select"
                            label="Level"
                            value={stream}
                            onChange={(e) => setStream(e.target.value)}
                            fullWidth
                            required
                          >
                             <MenuItem value="FullStack">fullStack</MenuItem>
                            <MenuItem value="DevOps">devops</MenuItem>
                            <MenuItem value="FrontEnd">Front end</MenuItem>
                            <MenuItem value="NodeJS">Node.JS</MenuItem>
                            <MenuItem value="SalesForce">SalesForce</MenuItem>
                            <MenuItem value="Data">data</MenuItem>
                            <MenuItem value="ERP">ERP</MenuItem>
                            <MenuItem value="Dotnet">Dotnet</MenuItem>
                            <MenuItem value="Java">Java</MenuItem>
                            <MenuItem value="CSOD">CSOD</MenuItem>
                            <MenuItem value="Testing">Testing</MenuItem>
                            <MenuItem value="Fresher">Fresher</MenuItem>

                            <MenuItem value="others">others</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} fullWidth>
                        <InputLabel id="skill-set">skill set</InputLabel>
                        <MultiSelect
                          labelId="skill-set"
                          placeholder="Skills"
                          options={options}
                          variant="outlined"
                          value={options.filter(obj => skills.includes(obj.value))}
                          onChange={handleSelectValue}
                          labelledBy="Skills"
                        />
                      </Grid>

                    </div>

                  </Grid>
                  <Grid xs={12} sm={0} >
                    <Typography gutterBottom variant="h5" align="left" paddingLeft={2} marginLeft={0} marginTop={1} style={{ background: "#787878", fontFamily: "sans-serif", color: "white" }}>
                      Experience
                    </Typography>
                    <div className="skills" style={{ display: "flex", gap: 4 }} >
                      <Grid item xs={12} sm={4} >
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="existing-status">ExistingEmployee(Yes/No)</InputLabel>
                          <Select
                            labelId="existing-status"
                            id="existing-status-id"
                            value={ExistingEmployee}
                            label="ExistingEmployee"
                            onChange={(e) => setExistingEmployee(e.target.value)}
                            fullWidth

                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="demo-simple-select-label">Experience</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={level}
                            label="Level"
                            onChange={(e) => setLevel(e.target.value)}
                            fullWidth

                          >
                            <MenuItem value="intern">Intern</MenuItem>
                            <MenuItem value="fresher">Fresher</MenuItem>
                            <MenuItem value="experienced">experienced</MenuItem>
                            <MenuItem value="Team Lead">Team Lead</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          placeholder="Total experience in years"
                          label="experience in years"
                          // variant="outlined"
                          value={totalExperience}
                          onChange={(e) => settotalExperience(e.target.value)}
                          fullWidth

                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          placeholder="relevant experience"
                          label="relevant experience"
                          // variant="outlined"
                          value={relevantExperience}
                          onChange={(e) => setrelevantExperience(e.target.value)}
                          fullWidth
                        />
                      </Grid>
                    </div>
                    <div className="skills" style={{ display: "flex", gap: 3, marginTop: 4 }} >
                      <Grid item xs={12} sm={4}>
                        <TextField
                          placeholder="CTC"
                          label="CTC"
                          // variant="outlined"
                          value={Ctc}
                          onChange={(e) => setCtc(e.target.value)}
                          fullWidth

                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          placeholder="ETC"
                          label="ETC"
                          // variant="outlined"
                          value={Etc}
                          onChange={(e) => setEtc(e.target.value)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          // inputProps={{
                          //   style: {
                          //     height: "5em",
                          //   },
                          // }}
                          placeholder="Current project"
                          label="Current Project"
                          multiline
                          maxRows={4}
                          variant="outlined"
                          value={CurrentProject}
                          onChange={(e) => setCurrentProject(e.target.value)}
                          fullWidth

                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          placeholder="Location prefered"
                          label="Location Prefered"
                          // variant="outlined"
                          value={LocationPrefered}
                          onChange={(e) => setLocationPrefered(e.target.value)}
                          fullWidth
                        />
                      </Grid>
                    </div>
                  </Grid>
                  <Grid xs={12} sm={0} >
                    <Typography gutterBottom variant="h5" align="left" paddingLeft={2} marginLeft={0} marginTop={1} style={{ background: "#787878", fontFamily: "sans-serif", color: "white" }}>
                      Interview Status
                    </Typography>
                    <div className="interview" style={{ display: "flex", gap: 3 }} >
                      <Grid item xs={12} sm={4} >
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="selected-status">Shortlisted(Yes/No)</InputLabel>
                          <Select
                            labelId="selected-status"
                            id="selected-status-id"
                            value={shortlisted}
                            label="Level"
                            onChange={(e) => setShortlisted(e.target.value)}
                            fullWidth

                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="NO">No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} >
                        <TextField
                          placeholder="enter date in dd-mm-yyyy format"
                          label="Joining availability(dd-mm-yyyy)"
                          variant="outlined"
                          value={DateOfav}
                          onChange={(e) => setDateOfav(e.target.value)}
                          fullWidth

                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          placeholder="enter date in dd-mm-yyyy format"
                          label="Interview Schedule(dd-mm-yyyy)"
                          variant="outlined"
                          value={interviewSchedule}
                          onChange={(e) => setInterviewSchedule(e.target.value)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} >
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="interview-status">Interview status</InputLabel>
                          <Select
                            labelId="interview-status"
                            id="demo-simple-select"
                            value={interviewStatus}
                            label="Level"
                            onChange={(e) => setinterviewStatus(e.target.value)}
                            fullWidth

                          >
                            <MenuItem value="yet to be done">Yet to be done</MenuItem>
                            <MenuItem value="completed">Completed</MenuItem>
                            <MenuItem value="Selected">Selected</MenuItem>
                            <MenuItem value="Rejected">Rejected</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={4} >
                        <TextField
                          placeholder="reference by"
                          label="Reference"
                          variant="outlined"
                          value={reference}
                          onChange={(e) => setReference(e.target.value)}
                          fullWidth
                        />
                      </Grid>
                    </div>
                    <div className="interview" style={{ display: "flex", gap: 3, marginTop: 4 }} >
                      <Grid item xs={12} sm={6} >
                        <TextField
                          placeholder="Communication"
                          label="Communication"
                          variant="outlined"
                          value={Communication}
                          onChange={(e) => setCommunication(e.target.value)}
                          fullWidth

                        />
                      </Grid>
                      <Grid item xs={12} sm={6} >
                        <TextField
                          placeholder="Current Location"
                          label="Current Location"
                          variant="outlined"
                          value={CurrentLocation}
                          onChange={(e) => setCurrentLocation(e.target.value)}
                          fullWidth

                        />
                      </Grid>
                    </div>
                    <div className="interview" style={{ display: "flex", gap: 3, marginTop: 4 }} >
                      <Grid item xs={12} sm={4} >
                        <TextField
                          inputProps={{
                            style: {
                              height: "5em",
                            },
                          }}
                          placeholder="First Round"
                          label="First Round"
                          multiline
                          maxRows={4}
                          variant="outlined"
                          value={FirstRound}
                          onChange={(e) => setFirstRound(e.target.value)}
                          fullWidth

                        />
                      </Grid>
                      <Grid item xs={12} sm={4} >
                        <TextField
                          inputProps={{
                            style: {
                              height: "5em",
                            },
                          }}
                          placeholder="Second Round"
                          label="Second Round"
                          multiline
                          maxRows={4}
                          variant="outlined"
                          value={SecondRound}
                          onChange={(e) => setSecondRound(e.target.value)}
                          fullWidth

                        />
                      </Grid>
                      <Grid item xs={12} sm={4} >
                        <TextField
                          inputProps={{
                            style: {
                              height: "5em",
                            },
                          }}
                          placeholder="Enter any remarks"
                          label="Remarks"
                          multiline
                          maxRows={4}
                          variant="outlined"
                          value={remarks}
                          onChange={(e) => setRemarks(e.target.value)}
                          fullWidth

                        />
                      </Grid>


                    </div>
                    <Typography gutterBottom variant="h5" align="left" paddingLeft={2} marginLeft={0} marginTop={1} style={{ background: "#787878", fontFamily: "sans-serif", color: "white" }}>
                      Files
                    </Typography>
                  </Grid>

                  <Grid>
                    <div className="interview" style={{ display: "flex", gap: 420, marginTop: 4 }} >

                      <Grid fullWidt sx={{ display: 'flex', gap: '2em' }}>
                        <FileUpload setprofileResume={setprofileResume} />
                        <Button variant="contained" sx={{ marginTop: "1em", marginBottom: '1em', marginLeft: "0.75em", height: '2.5em' }} onClick={handleResumeUpload}>Upload</Button>
                      </Grid>



                      <Grid fullWidth sx={{ display: 'flex', gap: '3.5em' }}>
                        <ImgUpload setprofileImage={setprofileImage} />
                        <Button variant="contained" sx={{ marginTop: "1em", marginBottom: '1em', marginLeft: "0.75em", height: '2.5em' }} onClick={handleImageUpload} >Upload</Button>
                      </Grid>


                    </div>
                  </Grid>

                  <Grid item xs={12} >
                    <Button
                      onClick={updateApplicant}
                      type="button"
                      variant="contained"
                      fullWidth
                      style={{ background: "#008080" }}>
                      Submit
                    </Button>
                  </Grid>

                </Grid>

              </form>
            </CardContent>
          </Card>
        </Grid>
      </div>
    </div>

  );
}










