import React, { useEffect, useState } from 'react';
import ExcelJS from 'exceljs';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import Iconify from '../components/iconify';


const ExcelExport = (userdata) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
      
        setUsers(userdata); // Set the `userdata` directly
      } catch (e) {
        console.error(e);
      }
    };
    fetchUsers();
}, [userdata]);

  const handleExport = async () => {
    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet('My Users');
    worksheet.columns = [
      { header: 'S.no', key: 's_no', width: 20 },
      { header: 'First Name', key: 'firstName', width: 20 },
      { header: 'Last Name', key: 'lastName', width: 20 },
      { header: 'Contact No', key: 'MobileNo', width: 20 },
      { header: 'Email', key: 'email', width: 30 },
      { header: 'Total Experience', key: 'totalExperience', width: 20 },
      {
        header: 'Relevant Experience',
        key: 'relevantExperience',
        width: 20,
      },
      { header: 'CTC', key: 'Ctc', width: 20 },
      { header: 'ETC', key: 'Etc', width: 20 },
      { header: '  Location Prefered', key: 'LocationPrefered', width: 20 },
      { header: '  Current Location', key: 'CurrentLocation', width: 20 },
      { header: 'Joining Availability', key: 'Dateofav', width: 20 },
      { header: 'Communication', key: 'Communication', width: 20 },
      {header: 'Interview Schedule', key: 'interviewSchedule', width: 20},
      { header: 'Status', key: 'interviewStatus', width: 20 },
      { header: 'HR Remarks', key: 'remarks', width: 20 },
      { header: ' First Round', key: 'FirstRound', width: 20 },
      { header: 'Second Round', key: 'SecondRound', width: 20 },

      { header: 'ExistingEmployee', key: 'ExistingEmployee', width: 20 },
      {
        header: 'Education Qualification',
        key: 'educationQualification',
        width: 10,
      },
      { header: 'Branch', key: 'branch', width: 10 },
      { header: 'Skills', key: 'skills', width: 50 },
      { header: 'year Of Passout', key: 'yearOfPassout', width: 20 },
      { header: 'Date Of Birth', key: 'DateOfBirth', width: 20 },
      // { header: 'City', key: 'city', width: 20 },
      // { header: 'Zipcode', key: 'zipcode', width: 20 },
      { header: 'level', key: 'level', width: 20 },
      { header: 'Percentage', key: 'Percentage', width: 20 },
     
      { header: 'Stream', key: 'stream', width: 20 },
      { header: 'Date Of Join', key: 'DateOfJoin', width: 20 },
      // { header: 'Interviewer', key: 'interviewer', width: 20 },
      { header: 'Shortlisted', key: 'shortlisted', width: 20 },
      // { header: 'Address', key: 'address', width: 80 },
      { header: 'Reference', key: 'reference', width: 20 },
      { header: 'profile Image', key: 'profileImage', width: 20 },
      { header: 'profile Resume', key: 'profileResume', width: 20 },
    
      { header: ' Current Project', key: 'CurrentProject', width: 20 },

    ];

    let count = 1;
    console.log("ekjfbkj",users)
    users.userdata.forEach((user) => {
      user.s_no = count;
       worksheet.addRow(user);
      count += 1;
    });

    const buffer = await workbook.xlsx.writeBuffer();

    const blob = new Blob([buffer], {
      type:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url
    link.setAttribute('download', 'my-users.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Tooltip title="download excel">
    <IconButton sx={{    position: "relative",
    top: "3em",
    zIndex: 2}}>
        <Box >
          <Typography sx={{position: "relative",top: "-3px"}}>
          <Button onClick={handleExport} variant="contained" startIcon={<Iconify icon="ic:round-filter-list" />}>
            excel
          </Button>
          </Typography>
        </Box>
      </IconButton>
      </Tooltip>
  );
};

export default ExcelExport;

