import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  Button,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { useParams } from 'react-router-dom'; // If needed
import { UserListHead } from '../sections/@dashboard/user';
import { Toolbar, Tooltip, IconButton, OutlinedInput, InputAdornment, Box } from '@mui/material';
// component
import Iconify from 'src/components/iconify/Iconify';
import '../App.css'

const TABLE_HEAD = [
  { id: 'jobCode', label: 'Job Code', alignRight: false },
  { id: 'jobTitle', label: 'Job Title', alignRight: false },
  { id: 'postedBy', label: 'Posted By', alignRight: false },
  { id: 'jobDescription', label: 'Description', alignRight: false },
  { id: 'minExperience', label: 'Min Experience', alignRight: false },
  { id: 'maxExperience', label: 'Max Experience', alignRight: false },
  { id: 'salary', label: 'Salary', alignRight: false },
  { id: 'location', label: 'Location', alignRight: false },
  { id: 'employmentType', label: 'Employment Type', alignRight: false },
  { id: 'technicalSkills', label: 'Technical Skills', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'isActive', label: 'Active', alignRight: false },
];

const AllJobs = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('jobTitle');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [clickedIndex, setClickedIndex] = useState(-1);

  const params = useParams(); // If you need to access route parameters

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await fetch('https://careersbe.vithiit.com/getalljobs', {
          method: 'GET',
          headers: {
            authorization: JSON.parse(localStorage.getItem('token')),
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setJobs(data);
        } else {
          const errorData = await response.json();
          setError(`Error fetching jobs: ${response.statusText}`);
          console.error('Error fetching jobs:', response.statusText, errorData);
        }
      } catch (error) {
        setError('Error fetching jobs. Please try again.');
        console.error('Error fetching jobs:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, []);

  console.log(jobs,"jobs");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setPage(0); // Reset page when filter changes
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - jobs.length) : 0;

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function applySortFilter(array, comparator, query) {
    if (array.length === 0) {
      return [];
    }
    

    // Apply search query filter
    let filteredArray = array.filter(job =>
      job.jobCode.toLowerCase().includes(query.toLowerCase()) ||
      job.jobTitle.toLowerCase().includes(query.toLowerCase()) ||
      job.postedBy.toLowerCase().includes(query.toLowerCase()) ||
      job.jobDescription.toLowerCase().includes(query.toLowerCase()) ||
      job.minExperience.toString().includes(query.toLowerCase()) ||
      job.maxExperience.toString().includes(query.toLowerCase()) ||
      job.salary.toLowerCase().includes(query.toLowerCase()) ||
      job.location.toLowerCase().includes(query.toLowerCase()) ||
      job.employmentType.some(type => type.toLowerCase().includes(query.toLowerCase())) ||
      job.technicalSkills.toLowerCase().includes(query.toLowerCase()) ||
      job.role.toLowerCase().includes(query.toLowerCase()) ||
      (job.isActive ? 'active' : 'inactive').includes(query.toLowerCase())
    );

    // Apply sorting
    filteredArray.sort(comparator);

    return filteredArray;
  }

  const filteredJobs = applySortFilter(jobs, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredJobs.length && !!filterName;
  const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    transition: theme.transitions.create(['box-shadow', 'width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': {
      width: 320,
      boxShadow: theme.customShadows.z8,
    },
    '& fieldset': {
      borderWidth: `1px !important`,
      borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
    },
  }));

  return (
    <>
      <Helmet>
        <title> Jobs | Vithi IT </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Job Listings
          </Typography>
          {/* Add link to new job form */}
          <Link to="/dashboard/createjobapplication">
            <Button variant="contained" color="primary">
              + New Job
            </Button>
          </Link>
        </Stack>

        <Card>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            {/* Job listing toolbar or filter */}
            <Paper component="form" elevation={3} sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 'auto' }}>
              {/* Job listing search/filter input */}
              {/* <input
                type="text"
                placeholder="Search jobs..."
                value={filterName}
                onChange={handleFilterByName}
                sx={{ ml: 1, flex: 1 }}
              /> */}
              <StyledSearch
          value={filterName}
          onChange={handleFilterByName}
          placeholder="Search job..."
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />
              {/* Add more filter options if needed */}
            </Paper>
          </Stack>

          {/* Job listing table */}
          <TableContainer>
            <Table className='oswn'>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                onRequestSort={handleRequestSort}
              />
              <TableBody className='oswn'>
                {filteredJobs
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((job, index) => {
                    const { id, jobTitle,jobCode, postedBy, jobDescription, minExperience, maxExperience, salary, location, employmentType, technicalSkills, role, isActive } = job;

                    return (
                      <TableRow hover key={id}>
                         <TableCell></TableCell>
                         <a href={`/jobdetails/${job.jobLink}`}>
                        <TableCell style={{color:'blue',textDecoration:'underline'}}>{jobCode}</TableCell>
                        </a>
                        <TableCell>{jobTitle}</TableCell>
                        <TableCell>{postedBy}</TableCell>
                        {/* <TableCell>{jobDescription}</TableCell> */}
                        <TableCell>{minExperience}</TableCell>
                        <TableCell>{maxExperience}</TableCell>
                        <TableCell>{salary}</TableCell>
                        <TableCell>{location}</TableCell>
                        <TableCell>{employmentType.join(', ')}</TableCell>
                        <TableCell>{technicalSkills}</TableCell>
                        <TableCell>{role}</TableCell>
                        <TableCell>{isActive ? 'Active' : 'Inactive'}</TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={11} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Pagination for jobs listing */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredJobs.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          {/* Display if no jobs found */}
          {isNotFound && (
            <Typography variant="body2" sx={{ p: 2 }}>
              No jobs found matching "{filterName}".
            </Typography>
          )}
        </Card>
      </Container>
    </>
  );
};

export default AllJobs;
