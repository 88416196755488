import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import PrivateComponent from './privateComponent';

import DashboardAppPage from './pages/DashboardAppPage';
import ApplicationForm from './layouts/form/form';
import UpdateApplication from './layouts/form/updateForm';
import ApplicantDetails from './layouts/form/ApplicantDetails';
import CheckboxGroup from './layouts/form/form2';
import JobForm from './layouts/form/AddJob';
import AllJobs from './pages/jobsPage';
import JobDetails from './pages/jobDetails';
import UpdateJobForm from './pages/updateJobDetails';
// import JobList from './pages/samplejobpage';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      element: <PrivateComponent />,
      children: [
        {
          path: '/dashboard',
          element: <DashboardLayout />,
          children: [
            { element: <Navigate to="/dashboard/app" />, index: true },
            { path: 'app', element: <DashboardAppPage /> },
            { path: 'user', element: <UserPage /> },
            { path: 'alljobs', element: <AllJobs /> },
            { path: 'createjobapplication', element: <JobForm /> },
          ],
        },
        {
          path: '/UpdateApplication/:id',
          element: <UpdateApplication />,
        },
        {
          path: '/yash',
          element: <CheckboxGroup />,
        },
        {
          path: '/applicantdetails/:id',
          element: <ApplicantDetails />,
        },

        

        {
          element: <DashboardLayout />,
          children: [
            {
              path: '/jobdetails/:id',
              element: <JobDetails />,
            },
            {
              path: '/updatejob/:id',
              element: <UpdateJobForm />,
            },
          ],
        },
        {
          element: <SimpleLayout />,
          children: [
            { element: <Navigate to="/dashboard/app" />, index: true },
            { path: '404', element: <Page404 /> },
            { path: '*', element: <Navigate to="/404" /> },
            { path: 'applicationform', element: <ApplicationForm /> },
            // {path:"createjobapplication",element:<JobForm/>},

            // {path:"jobdetails",element:<JobDetails/>}
          ],
        },

        {
          path: '*',
          element: <Navigate to="/404" replace />,
        },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
  ]);

  return routes;
}
