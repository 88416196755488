import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Container, Typography, Card, Stack, Button, Grid, Chip, Box } from '@mui/material';
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

const JobDetails = () => {
  const [jobDetails, setJobDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const paramsid = useParams();
  const id = paramsid.id;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await fetch(`https://careersbe.vithiit.com/getjobbyid/${id}`, {
          method: 'GET',
          headers: {
            authorization: JSON.parse(localStorage.getItem('token')),
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setJobDetails(data);
        } else {
          const errorData = await response.json();
          setError(`Error fetching job details: ${response.statusText}`);
          console.error('Error fetching job details:', response.statusText, errorData);
        }
      } catch (error) {
        setError('Error fetching job details. Please try again.');
        console.error('Error fetching job details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchJobDetails();
  }, [id]);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error: {error}</Typography>;
  }

  if (!jobDetails) {
    return <Typography>No job details found.</Typography>;
  }

  const {
    jobTitle,
    postedBy,
    jobDescription,
    minExperience,
    maxExperience,
    salary,
    location,
    employmentType,
    coreTech,
    technicalSkills,
    role,
    isActive,
    roles,
    responsibilities,
  } = jobDetails;

  // Convert roles and responsibilities from JSON to HTML
  let rolesHTML = '';
  let responsibilitiesHTML = '';

  if (roles) {
    const rolesContentState = convertFromRaw(JSON.parse(roles));
    rolesHTML = stateToHTML(rolesContentState);
  }

  if (responsibilities) {
    const responsibilitiesContentState = convertFromRaw(JSON.parse(responsibilities));
    responsibilitiesHTML = stateToHTML(responsibilitiesContentState);
  }

  return (
    <>
      <Helmet>
        <title>{jobTitle} | vithi</title>
      </Helmet>
      
      <Container maxWidth="md">
        <Stack spacing={3} mb={5}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h4">{jobTitle}</Typography>
            <Button variant="contained" color="primary" onClick={() => navigate(`/updatejob/${id}`)}>
              Update
            </Button>
          </Box>
          <Typography variant="subtitle1">Posted by: {postedBy}</Typography>
          <Typography variant="body1">{jobDescription}</Typography>
          
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2"><strong>Location:</strong> {location}</Typography>
              <Typography variant="body2"><strong>Salary:</strong> {salary}</Typography>
              <Typography variant="body2"><strong>Experience:</strong> {minExperience} - {maxExperience} years</Typography>
              <Typography variant="body2">
                <strong>Employment Type:</strong>
                {employmentType.map(type => (
                  <Chip key={type} label={type} size="small" style={{ marginRight: 5 }} />
                ))}
              </Typography>
              <Typography variant="body2"><strong>core technology:</strong> {coreTech}</Typography>
              <Typography variant="body2"><strong>Technical Skills:</strong> {technicalSkills}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2"><strong>Role:</strong> {role}</Typography>
              <Typography variant="body2"><strong>Status:</strong> {isActive ? 'Active' : 'Inactive'}</Typography>
            </Grid>
          </Grid>

          {/* Render roles and responsibilities as HTML */}
          {rolesHTML && (
            <Card>
              <Typography variant="h6">Roles</Typography>
              <div dangerouslySetInnerHTML={{ __html: rolesHTML }} />
            </Card>
          )}

          {responsibilitiesHTML && (
            <Card>
              <Typography variant="h6">Responsibilities</Typography>
              <div dangerouslySetInnerHTML={{ __html: responsibilitiesHTML }} />
            </Card>
          )}

          {/* Example button to navigate back */}
          <Button variant="contained" color="primary" onClick={() => window.history.back()}>
            Back to Job Listings
          </Button>
        </Stack>
      </Container>
    </>
  );
};

export default JobDetails;
