import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../App.css';
import { MultiSelect } from 'react-multi-select-component';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
/* eslint-disable */

const JobForm = () => {
  const [loading, setLoading] = useState(true);
  const [postedByName, setPostedByName] = useState('');

  const navigate = useNavigate()
  useEffect(() => {
    const fetchData = async () => {
      const userdetails = localStorage.getItem('user');
      if (userdetails) {
        const user = JSON.parse(userdetails);
        setPostedByName(user.name || '');
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const initialValues = {
    jobTitle: '',
    jobLink:'',
    jobDescription: '',
    roles: EditorState.createEmpty(),
    responsibilities: EditorState.createEmpty(),
    minExperience: '',
    maxExperience: '',
    salary: '',
    location: '',
    employmentType: [],
    coreTech: '',
    technicalSkills: '',
    role: '',
    isActive: false,
    postedBy: postedByName,
  };

  const employmentTypeOptions = [
    { value: 'Full Time', label: 'Full Time' },
    { value: 'Hybrid', label: 'Hybrid' },
    { value: 'Remote', label: 'Remote' },
  ];

  const coreTechOptions = [
    'FullStack',
    'DevOps',
    'NodeJS',
    'SalesForce',
    'Data',
    'ERP',
    'Java',
    'CSOD',
    'Testing',
    'Fresher',
  ];
  const overrideStrings = {
    allItemsAreSelected: '', // Override the "All items are selected" text
    selectSomeItems: 'Select options',
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log('values', values);
    const rolesContent = JSON.stringify(convertToRaw(values.roles.getCurrentContent()));
    const responsibilitiesContent = JSON.stringify(convertToRaw(values.responsibilities.getCurrentContent()));
    const payload = {
      ...values,
      roles: rolesContent,
      responsibilities: responsibilitiesContent,
    };

    console.log('Payload:', payload);

    try {
      const response = await fetch('https://careersbe.vithiit.com/createjob', {
        method: 'POST',
        headers: {
          authorization: JSON.parse(localStorage.getItem('token')),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      console.log('resp', response);

      if (response.ok) {
        const result = await response.json();
        console.log('Job created successfully:', result);
        alert('Job created successfully');
        navigate('/dashboard/alljobs')
        
      } else {
        console.error('Error creating job:', response.statusText, errorData);
        alert(`Error creating job: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error creating job:', error, errorData);
      alert('Error creating job. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }
  const validationSchema = Yup.object({
    jobTitle: Yup.string().required('Job Title is required'),
    jobLink: Yup.string().required('Job link is required'),
    jobDescription: Yup.string().required('Job Description is required'),
    minExperience: Yup.number().required('Minimum Experience is required').min(0, 'Experience cannot be negative'),
    maxExperience: Yup.number().required('Maximum Experience is required').min(Yup.ref('minExperience'), 'Max Experience must be greater than or equal to Min Experience'),
    salary: Yup.string().required('Salary is required'),
    location: Yup.string().required('Location is required'),
    employmentType: Yup.array().min(1, 'At least one Employment Type is required'),
    coreTech: Yup.string().required('Core Tech is required'),
    technicalSkills: Yup.string().required('Technical Skills are required'),
    role: Yup.string().required('Role is required'),
  });

  return (
    <div className=" container">
      <h2 className="jobTitle">Job Form</h2>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <div className="form-group">
              <label htmlFor="jobTitle">Job Title</label>
              <Field id="jobTitle" name="jobTitle" type="text" className="form-control" />
            </div>
            <div className="formStyleMain">
            <div className="form-group">
              <label htmlFor="jobLink">Job Link</label>
              <Field id="jobLink" name="jobLink" type="text" className="form-control" />
            </div>
              <div className="form-group">
                <label htmlFor="minExperience">Minimum Experience</label>
                <Field as="select" id="minExperience" name="minExperience" className="form-control">
                  {[...Array(41).keys()].map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </Field>
              </div>

              <div className="form-group">
                <label htmlFor="maxExperience" className="label">
                  Maximum Experience
                </label>
                <Field as="select" id="maxExperience" name="maxExperience" className="form-control">
                  {[...Array(41).keys()].map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </Field>
              </div>

              <div className="form-group">
                <label htmlFor="salary">Salary</label>
                <Field id="salary" name="salary" type="text" className="form-control" />
              </div>

              <div className="form-group">
                <label htmlFor="location">Location</label>
                <Field id="location" name="location" type="text" className="form-control" />
              </div>

              <div className="form-group">
                <label htmlFor="employmentType">Employment Type</label>
                <MultiSelect
                  labelId="employmentType"
                  id="employmentType"
                  name="employmentType"
                  placeholder="Select Employment Type"
                  options={employmentTypeOptions}
                  value={values.employmentType.map((value) =>
                    employmentTypeOptions.find((option) => option.value === value)
                  )}
                  onChange={(selected) =>
                    setFieldValue(
                      'employmentType',
                      selected.map((option) => option.value)
                    )
                  }
                  disableSearch
                  hasSelectAll={false}
                  overrideStrings={overrideStrings}
                />
              </div>

              <div className="form-group">
                <label htmlFor="coreTech" className="label">
                  Core Tech
                </label>
                <Field as="select" id="coreTech" name="coreTech" className="form-control">
                  <option value="" disabled>
                    Select Tech
                  </option>
                  {coreTechOptions.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </Field>
              </div>
              <div className="form-group">
                <label htmlFor="technicalSkills">Technical Skills</label>
                <Field id="technicalSkills" name="technicalSkills" type="text" className="form-control" />
              </div>

              <div className="form-group">
                <label htmlFor="role">Role</label>
                <Field id="role" name="role" type="text" className="form-control" />
              </div>

              <div className="form-group activeclass gap-3">
                <Field id="isActive" name="isActive" type="checkbox" />
                <label htmlFor="isActive">Is Active</label>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="jobDescription">Job Description</label>
              <Field as="textarea" id="jobDescription" name="jobDescription" className="form-control h-10" />
            </div>

            <div className="form-group">
              <label htmlFor="roles">Requirements</label>
              <Editor
                editorState={values.roles}
                wrapperClassName="editor-wrapper"
                editorClassName="editor-content"
                onEditorStateChange={(editorState) => setFieldValue('roles', editorState)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="responsibilities">Responsibilities</label>
              <Editor
                editorState={values.responsibilities}
                wrapperClassName="editor-wrapper"
                editorClassName="editor-content"
                onEditorStateChange={(editorState) => setFieldValue('responsibilities', editorState)}
              />
            </div>
            <div className="">
              <button
                type="submit"
                className="btn btn-primary"
                style={{ padding: '0.5rem 3rem' }}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default JobForm;
