// UpdateJobForm.js
/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../App.css';
import { MultiSelect } from 'react-multi-select-component';

const UpdateJobForm = () => {
    const  jobId  = useParams();
    const [jobDetails, setJobDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate()

    useEffect(() => {
        const fetchJobDetails = async () => {
            try {
                const response = await fetch(`https://careersbe.vithiit.com/getjobbyid/${jobId.id}`, {
                    method: 'GET',
                    headers: {
                        authorization: JSON.parse(localStorage.getItem('token')),
                        'Content-Type': 'application/json',
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setJobDetails(data);
                } else {
                    const errorData = await response.json();
                    setError(`Error fetching job details: ${response.statusText}`);
                    console.error('Error fetching job details:', response.statusText, errorData);
                }
            } catch (error) {
                setError('Error fetching job details. Please try again.');
                console.error('Error fetching job details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchJobDetails();
    }, [jobId]);

    const employmentTypeOptions = [
        { value: 'Full Time', label: 'Full Time' },
        { value: 'Hybrid', label: 'Hybrid' },
        { value: 'Remote', label: 'Remote' },
    ];
      const coreTechOptions = [
        'FullStack',
        'DevOps',
        'NodeJS',
        'SalesForce',
        'Data',
        'ERP',
        'Java',
        'CSOD',
        'Testing',
        'Fresher',
        'Others'
      ];

    const overrideStrings = {
        allItemsAreSelected: "", // Override the "All items are selected" text
        selectSomeItems: "Select options",
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!jobDetails) {
        return <div>No job details found.</div>;
    }

    const initialValues = {
        jobTitle: jobDetails.jobTitle || '',
        jobLink:jobDetails.jobLink||'',
        jobDescription: jobDetails.jobDescription || '',
        roles: EditorState.createWithContent(convertFromRaw(JSON.parse(jobDetails.roles))),
        responsibilities: EditorState.createWithContent(convertFromRaw(JSON.parse(jobDetails.responsibilities))),
        minExperience: jobDetails.minExperience.toString() || '',
        maxExperience: jobDetails.maxExperience.toString() || '',
        salary: jobDetails.salary || '',
        location: jobDetails.location || '',
        employmentType: jobDetails.employmentType || [],
        coreTech: jobDetails.coreTech||'',
        technicalSkills: jobDetails.technicalSkills || '',
        role: jobDetails.role || '',
        isActive: jobDetails.isActive || false,
        postedBy: jobDetails.postedBy || '',
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        const rolesContent = JSON.stringify(convertToRaw(values.roles.getCurrentContent()));
        const responsibilitiesContent = JSON.stringify(convertToRaw(values.responsibilities.getCurrentContent()));
        const payload = {
            ...values,
            roles: rolesContent,
            responsibilities: responsibilitiesContent,
        };

        try {
            const response = await fetch(`https://careersbe.vithiit.com/updatejob/${jobId.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: JSON.parse(localStorage.getItem('token')),
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                const result = await response.json();
                console.log('Job updated successfully:', result);
                alert("Job updated successfully");
                navigate('/dashboard/alljobs')
            } else {
                const errorData = await response.json();
                setError(`Error updating job: ${response.statusText}`);
                console.error('Error updating job:', response.statusText, errorData);
            }
        } catch (error) {
            setError('Error updating job. Please try again.');
            console.error('Error updating job:', error);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div className=" container">
            <h2 className="jobTitle">Update Job</h2>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                {({ values, setFieldValue, isSubmitting }) => (
                    <Form>
                        <div className="form-group">
                            <label htmlFor="jobTitle">Job Title</label>
                            <Field id="jobTitle" name="jobTitle" type="text" className="form-control" />
                        </div>
                        <div className="formStyleMain">
                            <div className="form-group">
                                <label htmlFor="minExperience">Minimum Experience</label>
                                <Field as="select" id="minExperience" name="minExperience" className="form-control">
                                    {[...Array(41).keys()].map((value) => (
                                        <option key={value} value={value}>
                                            {value}
                                        </option>
                                    ))}
                                </Field>
                            </div>

                            <div className="form-group">
                                <label htmlFor="maxExperience" className="label">Maximum Experience</label>
                                <Field as="select" id="maxExperience" name="maxExperience" className="form-control">
                                    {[...Array(41).keys()].map((value) => (
                                        <option key={value} value={value}>
                                            {value}
                                        </option>
                                    ))}
                                </Field>
                            </div>

                            <div className="form-group">
                                <label htmlFor="salary">Salary</label>
                                <Field id="salary" name="salary" type="text" className="form-control" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="location">Location</label>
                                <Field id="location" name="location" type="text" className="form-control" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="employmentType">Employment Type</label>
                                <MultiSelect
                                    labelId="employmentType"
                                    id="employmentType"
                                    name="employmentType"
                                    placeholder="Select Employment Type"
                                    options={employmentTypeOptions}
                                    value={values.employmentType.map(value => employmentTypeOptions.find(option => option.value === value))}
                                    onChange={(selected) => setFieldValue('employmentType', selected.map(option => option.value))}
                                    disableSearch
                                    hasSelectAll={false}
                                    overrideStrings={overrideStrings}
                                />
                            </div>

                            <div className="form-group">
                <label htmlFor="coreTech" className="label">
                  Core Tech
                </label>
                <Field as="select" id="coreTech" name="coreTech" className="form-control">
                  <option value="" disabled>
                    Select Tech
                  </option>
                  {coreTechOptions.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </Field>
              </div>
                            <div className="form-group">
                                <label htmlFor="technicalSkills">Technical Skills</label>
                                <Field id="technicalSkills" name="technicalSkills" type="text" className="form-control" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="role">Role</label>
                                <Field id="role" name="role" type="text" className="form-control" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="jobDescription">Job Description</label>
                            <Field as="textarea" id="jobDescription" name="jobDescription" className="form-control h-10" />
                        </div>
                        <div className="form-group activeclass gap-3">
                            <Field id="isActive" name="isActive" type="checkbox" />
                            <label htmlFor="isActive">Is Active</label>
                        </div>

                        <div className="form-group">
                            <label htmlFor="roles">Requirements</label>
                            <Editor
                                editorState={values.roles}
                                wrapperClassName="editor-wrapper"
                                editorClassName="editor-content"
                                onEditorStateChange={(editorState) => setFieldValue('roles', editorState)}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="responsibilities">Responsibilities</label>
                            <Editor
                                editorState={values.responsibilities}
                                wrapperClassName="editor-wrapper"
                                editorClassName="editor-content"
                                onEditorStateChange={(editorState) => setFieldValue('responsibilities', editorState)}
                            />
                        </div>
                        <div className="">
                            <button type="submit" className="btn btn-primary" style={{ padding: '0.5rem 3rem' }} disabled={isSubmitting}>
                                {isSubmitting ? 'Submitting...' : 'Submit'}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default UpdateJobForm;
