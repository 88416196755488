import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
import { Link } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
// components
import Iconify from '../components/iconify';

// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';



// ----------------------------------------------------------------------


export default function DashboardAppPage() {
  const theme = useTheme();
  const [fullStack,setfullStack] = useState([])
  const [FrontEnd,setFrontEnd] = useState([])
  const [devOps,setdevOps] = useState([])
  const [NodeJS,setNodeJS]=useState([])
  const [SalesForce,setSalesForce] = useState([])
  const [data,setData] = useState([])
  const [ERP,setERP]=useState([])
  const [others,setOthers] = useState([])
  const [Fresher,setFresher]=useState([])
  const[Hybris,setHybris]=useState([])
  const[Testing,setTesting]=useState([])
  const[Php,setPhp]=useState([])
  const[Java,setJava]=useState([])
  const[Dotnet,setDotnet]=useState([])
  const[CSOD,setCSOD]=useState([])
  
const[country,setCountry]=useState([])
  
    useEffect(() =>{
        getApplications();
    },[]);
    const getApplications = async () =>{   

        let result = await fetch(`https://careersbe.vithiit.com/getStackDetails/${"FullStack"}`,{
            headers:{
                authorization:JSON.parse(localStorage.getItem('token'))
            }
        });
        result = await result.json();
        // console.log(result)
        setfullStack(result)
         result = await fetch(`https://careersbe.vithiit.com/getStackDetails/${"FrontEnd"}`,{
            headers:{
                authorization:JSON.parse(localStorage.getItem('token'))
            }
        });
        result = await result.json();
        // console.log(result)
        setFrontEnd(result)

        result = await fetch(`https://careersbe.vithiit.com/getStackDetails/${"DevOps"}`,{
            headers:{
                authorization:JSON.parse(localStorage.getItem('token'))
            }
        });
        result = await result.json();
        // console.log(result)
        setdevOps(result)
        result = await fetch(`https://careersbe.vithiit.com/getStackDetails/${"NodeJS"}`,{
          headers:{
              authorization:JSON.parse(localStorage.getItem('token'))
          }
      });
      result = await result.json();
      // console.log(result)
      setNodeJS(result)

        result = await fetch(`https://careersbe.vithiit.com/getStackDetails/${"SalesForce"}`,{
            headers:{
                authorization:JSON.parse(localStorage.getItem('token'))
            }
        });
        result = await result.json();
        // console.log(result)
        setSalesForce(result)
//
        result = await fetch(`https://careersbe.vithiit.com/getStackDetails/${"Data"}`,{
          headers:{
              authorization:JSON.parse(localStorage.getItem('token'))
          }
        });
        result = await result.json();
        // console.log("data",result)
        setData(result)

        result = await fetch(`https://careersbe.vithiit.com/getStackDetails/${"Fresher"}`,{
          headers:{
              authorization:JSON.parse(localStorage.getItem('token'))
          }
        });
        result = await result.json();
        // console.log(result)
        setFresher(result)

        result = await fetch(`https://careersbe.vithiit.com/getStackDetails/${"Hybris"}`,{
          headers:{
              authorization:JSON.parse(localStorage.getItem('token'))
          }
        });
        result = await result.json();
        // console.log(result)
        setHybris(result)

        result = await fetch(`https://careersbe.vithiit.com/getStackDetails/${"Testing"}`,{
          headers:{
              authorization:JSON.parse(localStorage.getItem('token'))
          }
        });
        result = await result.json();
        // console.log(result)
        setTesting(result)

        result = await fetch(`https://careersbe.vithiit.com/getStackDetails/${"Php"}`,{
          headers:{
              authorization:JSON.parse(localStorage.getItem('token'))
          }
        });
        result = await result.json();
        // console.log(result)
        setPhp(result)

        let result6 = await fetch(`https://careersbe.vithiit.com/getStackDetails/${"ERP"}`,{
          headers:{
              authorization:JSON.parse(localStorage.getItem('token'))
          }
        });
        result6 = await result6.json();

        // console.log("erp",result6)
        setERP(result6)
       result6 = await fetch(`https://careersbe.vithiit.com/getStackDetails/${"Java"}`,{
          headers:{
              authorization:JSON.parse(localStorage.getItem('token'))
          }
        });
        result6 = await result6.json();

        // console.log("erp",result6)
        setJava(result6)
       result6 = await fetch(`https://careersbe.vithiit.com/getStackDetails/${"Dotnet"}`,{
          headers:{
              authorization:JSON.parse(localStorage.getItem('token'))
          }
        });
        result6 = await result6.json();

        // console.log("erp",result6)
        setDotnet(result6)

        result6 = await fetch(`https://careersbe.vithiit.com/getStackDetails/${"CSOD"}`,{
          headers:{
              authorization:JSON.parse(localStorage.getItem('token'))
          }
        });
        result6 = await result6.json();

        // console.log("erp",result6)
        setCSOD(result6)
        const locations = ["US", "Australia"];  // you can change this to any dynamic values
const locationsQuery = locations.join(',');
         result = await fetch(`https://careersbe.vithiit.com/getByCountry?locations=${locationsQuery}`, {
          headers: {
              authorization: JSON.parse(localStorage.getItem('token'))
          }
      });
      
      result = await result.json();
      setCountry(result)



    result = await fetch(`https://careersbe.vithiit.com/getStackDetails/${"others"}`,{
      headers:{
          authorization:JSON.parse(localStorage.getItem('token'))
      }
    });
    result = await result.json();
    // console.log(result)
    setOthers(result)
}
    let SalesForceLength = 0
     let fullStackLength =0
     let FrontEndLength =0
     let devOpsLength = 0
     let NodeJSLength = 0
     let othersLength = 0
     let dataLength = 0
    let ERPLength = 0
     let FresherLength=0
     let HybrisLength=0
     let TestingLength=0
     let PhpLength=0
     let JavaLength=0
     let DotnetLength=0
     let CSODLength=0
     let CountryLength=0


     ERPLength = ERP.length === (undefined || 0 ) ? "0":ERP.length
     othersLength = others.length  === (undefined || 0 ) ? "0":others.length
      dataLength = data.length  === (undefined || 0 ) ? "0":data.length
     fullStackLength = fullStack.length  === (undefined || 0 ) ? "0":fullStack.length
     FrontEndLength = FrontEnd.length  === (undefined || 0 ) ? "0":FrontEnd.length
     devOpsLength = devOps.length  === (undefined || 0 ) ? "0":devOps.length
     NodeJSLength = NodeJS.length  === (undefined || 0 ) ? "0":NodeJS.length
     FresherLength=Fresher.length  === (undefined || 0 ) ? "0":Fresher.length
     HybrisLength=Hybris.length  === (undefined || 0 ) ? "0":Hybris.length
     TestingLength=Testing.length  === (undefined || 0 ) ? "0":Testing.length
     PhpLength=Php.length  === (undefined || 0 ) ? "0":Php.length
     SalesForceLength = SalesForce.length  === (undefined || 0 ) ? "0":SalesForce.length
     JavaLength = Java.length  === (undefined || 0 ) ? "0":Java.length
     DotnetLength = Dotnet.length  === (undefined || 0 ) ? "0":Dotnet.length
     CSODLength = CSOD.length  === (undefined || 0 ) ? "0":CSOD.length
     CountryLength = country.length  === (undefined || 0 ) ? "0":country.length


  

  return (
    <>
      <Helmet>
        <title> Dashboard | Vithi IT </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          
          <Grid item xs={12} sm={6} md={3}>
          <Link to='/Dashboard/user#FullStack'>
            <AppWidgetSummary title="FullStack" total={fullStackLength   === undefined ? "0": fullStackLength} icon={'ant-design:android-filled'} />
          </Link>
          </Grid>
          
          <Grid item xs={12} sm={6} md={3}>
          <Link to='/Dashboard/user#FrontEnd'>
            <AppWidgetSummary title="FrontEnd" total={FrontEndLength   === undefined ? "0":FrontEndLength} icon={'ant-design:android-filled'} />
          </Link>
          </Grid>
          

          <Grid item xs={12} sm={6} md={3}>
          <Link to='/Dashboard/user#Devops'>
            <AppWidgetSummary title="Devops" total={devOpsLength   === undefined ? "0": devOpsLength} color="info" icon={'ant-design:apple-filled'} />
          </Link>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
          <Link to='/Dashboard/user#NodeJS'>
            <AppWidgetSummary title="Node.js" total={NodeJSLength   === undefined ? "0": NodeJSLength} color="info" icon={'ant-design:apple-filled'} />
          </Link>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
          <Link to='/Dashboard/user#SalesForce'>
            <AppWidgetSummary title="SalesForce" total={SalesForceLength  === undefined ? "0": SalesForceLength} color="warning" icon={'ant-design:windows-filled'} />
          </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
          <Link to='/Dashboard/user#Data'>
            <AppWidgetSummary title="Data" total={dataLength  === undefined ? "0": dataLength} color="error" icon={'ant-design:bug-filled'} />
          </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
          <Link to='/Dashboard/user#ERP'>
            <AppWidgetSummary title="ERP" total={ERPLength  === undefined ? "0": ERPLength} color="error" icon={'ant-design:bug-filled'} />
          </Link>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
          <Link to='/Dashboard/user#Fresher'>
            <AppWidgetSummary title="Fresher" total={ FresherLength  === undefined ? "0": FresherLength} color="error" icon={'ant-design:bug-filled'} />
          </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
          <Link to='/Dashboard/user#Hybris'>
            <AppWidgetSummary title="Hybris" total={ HybrisLength  === undefined ? "0": HybrisLength} color="info" icon={'ant-design:apple-filled'} />
          </Link>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
          <Link to='/Dashboard/user#Testing'>
            <AppWidgetSummary title="Testing" total={ TestingLength  === undefined ? "0": TestingLength} color="info" icon={'ant-design:apple-filled'} />
          </Link>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
          <Link to='/Dashboard/user#Php'>
            <AppWidgetSummary title="Php" total={ PhpLength  === undefined ? "0": PhpLength} color="info" icon={'ant-design:apple-filled'} />
          </Link>
          </Grid>
          
          <Grid item xs={12} sm={6} md={3}>
          <Link to='/Dashboard/user#Java'>
            <AppWidgetSummary title="Java" total={JavaLength  === undefined ? "0": JavaLength} color="info" icon={'ant-design:apple-filled'} />
          </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
          <Link to='/Dashboard/user#Dotnet'>
            <AppWidgetSummary title="Dotnet" total={DotnetLength  === undefined ? "0": DotnetLength} color="info" icon={'ant-design:apple-filled'} />
          </Link>
          </Grid>
          
          <Grid item xs={12} sm={6} md={3}>
          <Link to='/Dashboard/user#CSOD'>
            <AppWidgetSummary title="CSOD" total={CSODLength  === undefined ? "0": CSODLength} color="error" icon={'ant-design:bug-filled'} />
          </Link>
          </Grid>
          
          <Grid item xs={12} sm={6} md={3}>
          <Link to='/Dashboard/user#others'>
            <AppWidgetSummary title="Others" total={othersLength  === undefined ? "0": othersLength} color="error" icon={'ant-design:bug-filled'} />
          </Link>
          </Grid>
          
<Grid item xs={12} sm={6} md={3}>
          <Link to='/Dashboard/user#country'>
            <AppWidgetSummary title="Country" total={CountryLength  === undefined ? "0": CountryLength} color="error" icon={'ant-design:bug-filled'} />
          </Link>
          </Grid>

        </Grid>
      </Container>
    </>
  );
}
